import Vue from 'vue'
import App from './App.vue'

// import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
// import 'vue-directive-image-previewer/dist/assets/style.css'

import 'default-passive-events'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


// Vue.use(VueDirectiveImagePreviewer, {
//   background: {     // or : background: '#000'
//     color: '#000' // or rgba or rgb     // or image: 'url(xxx)'
//   },
//   // transition
//   animate: {
//     duration: 600,
//     delay: 500
//   },
//   // loading (not supported)
//   loading: {
//     image: ''
//   },
//   // cursor(css)
//   cursor: 'pointer',
//   clickMethod: 'doubleClick'   // click or doubleClick(not supported)
// })

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true, // 启用 inline 模式
    'button': true, // 显示右上角关闭按钮
    'navbar': true, // 显示缩略图导航
    'title': true, // 显示当前图片的标题
    'toolbar': true, // 显示工具栏
    'tooltip': true, // 显示缩放百分比
    'movable': true, // 图片是否可移动
    'zoomable': true, // 图片是否可缩放
    'rotatable': true, // 图片是否可旋转
    'scalable': true, // 图片是否可翻转
    'transition': true, // 使用 CSS3 过度
    'fullscreen': true, // 播放时是否全屏s
    'keyboard': true, // 是否支持键盘
    'url': 'data-source' // 设置大图片的 url
  }
})

Vue.use(ElementUI);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
