import request from '../utils/request';

// 获取检测报告
export const get_checkreport = query => {
  return request({
    url: '/api/index/getDetectReport',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  });
};

// 获取空气骑士相关新闻详情
export const get_newsdet = query => {
  return request({
    url: '/api/welfare/getEssayDetail',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  });
};
// 浏览量
export const addViewNum = query => {
  return request({
    url: '/api/welfare/addViewNum',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  });
};

