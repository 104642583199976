<template>
  <div>
    <p class="aboutp">
      日记本（北京）健康科技有限公司是一家经国家卫生部批准，集技术研发、软件开发、产品生产、销售于一体的国家大力扶持的高新技术企业之一，是国内消毒领域新型产品的开创者。总部在北京，公司机构分别设立在重庆、杭州。
    </p>
    <p class="aboutp">
      公司拥有多项自主研发、自有知识产权的医护产品，包含空气消毒机、次氯酸水消毒液、智能媒体消毒机。消毒类产品空气消毒机、成品次氯酸水消毒液，均分为医用级、家庭版两类别；智能媒体消毒机，作用于医疗健康领域附带媒体宣传功能，可实现喷洒式手消毒，同时附带广告播放功能。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.aboutp {
  text-indent: 0.32rem;
  font-size: 0.16rem;
  padding: 0.12rem 0.16rem 0;
  line-height: 0.22rem;
}
</style>