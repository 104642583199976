<template>
<!-- 随访隐私政策 -->
  <div class="box5">
    <h4>用户隐私政策 <span style="font-weight: normal;font-size:0.08rem">版本：V1.0</span></h4>
    <p style="margin-top:0.1rem">感谢您选择使用我们的服务！</p>
    <p><strong>"我们"</strong>是日记本（北京）健康科技有限公司，注册地位于中国北京。我们的服务由我们和我们分布于全球的各分支机构、关联公司或合作伙伴共同为您提供。</p>
    <p><strong>"您"</strong>是指“宏康随访”的注册用户。</p>
    <p><strong>"我们的服务"</strong>是指我们通过“宏康随访”APP或我们发布的其它渠道（以下统称“我们的平台”）向您提供的互联网平台信息技术服务。</p>
    <p>保护您的隐私对我们至关重要，我们会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请您仔细阅读本《隐私政策》，该政策解释我们如何收集、使用以及披露我们在服务中收到的信息。您使用服务即表示同意我们的《隐私政策》，即同意我们按照本《隐私政策》收集、使用、处理和存储您的相关信息。如果您不同意本《隐私政策》，请您立即注销账户以终止使用我们的产品和服务。</p>
    <h4 style="text-align:center;margin:0.10rem 0">目录</h4>
    <p>我们的《隐私政策》解释了以下几个方面的问题：</p>
    <p><span>1、</span>我们如何收集和使用您的个人信息</p>
    <p><span>2、</span>我们如何使用Cookie等技术</p>
    <p><span>3、</span>我们如何共享、转让、公开披露您的个人信息</p>
    <p><span>4、</span>我们如何存储和保护您的个人信息</p>
    <p><span>5、</span>您的权利</p>
    <p><span>6、</span>我们如何保护未成年人的信息</p>
    <p><span>7、</span>本政策如何更新</p>
    <p><span>8、</span>如何联系我们</p>
    <h4 style="text-align:center;margin:0.10rem 0">隐私政策具体内容</h4>
    
    <h4>1、我们如何收集和使用您的个人信息</h4>
    <p>我们收集您的信息的主要目的是提供及改善我们的服务，便捷您对我们的服务的使用。我们会按照如下方式收集您在使用服务时主动提供的、以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息。</p>
    <p><span>1. </span>注册和登录</p>
    <p>当您注册、登录您的“宏康随访”APP账户使用我们的服务时，我们将收集您的手机号码用于确认您的身份。在您使用我们的服务时，我们也会根据服务内容的需要，向您收集必要的信息，并在收集时向您做出明确的提示。</p>
    <p><span>2. </span>资讯浏览</p>
    <p>您可浏览的文章可以点赞、收藏等。为此，我们可能会收集您使用”宏康随访”APP时的设备信息，包括设备型号、IMSI唯一设备标识符、设备SIM卡、操作系统、电信运营商信息。我们还可能收集您的浏览器类型，以此来为您提供信息展示的最优方案。此外，在您使用浏览功能的过程中，我们会自动收集您使用“宏康随访”APP的详细情况，并作为有关的网络日志保存，包括您输入的搜索关键词信息和点击的链接，您浏览的内容及评论信息，访问的日期和时间、及您请求的网页记录、操作系统、软件版本号、IP信息。在此过程中，我们会收集您的浏览记录，您可以自主删除浏览记录。如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>3. </span>搜索功能</p>
    <p>当您使用“宏康随访”APP提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用我们的服务时所阅览或要求的其他信息和内容详情。为了给您带来更便捷的搜索服务并不断完善我们的服务，我们可能会使用浏览器网络存储机制和应用数据缓存，收集您设备上的信息并进行本地存储。关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本政策的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本政策对其进行处理与保护。如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>4. </span>用药时间表</p>
    <p>您实名注册成为“宏康随访”APP的用户后，可在“宏康随访”APP用药时间表建立自己的吃药时间。在建立的过程中，可能会开启手机拍摄、读取相册、录音、读取外部存储及写入外部存储的相关权限，如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>5. </span>量表测试功能</p>
    <p>您可在“宏康随访”APP健康模块下的量表测试进行自测，可以用于检查自己身体状况。需要您提供您的身份信息，包括姓名、手机号码、身份证号码，如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>6. </span>复诊预约功能</p>
    <p>您可在“宏康随访”APP进行在线复诊预约。在您使用该功能的过程中，因互联网医院就医实行实名制要求，需要您提供您的身份信息，包括姓名、手机号码、身份证号码，部分复诊预约医院还需要提供用户所在地区、详细地址信息。如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>7. </span>医疗记录功能</p>
    <p>当您在使用医疗记录功能时，我们需要您向我们授权拍摄并读取相册、读取外部存储、使用麦克风及写入外部存储的相关权限，用于完善用户医疗记录相关资料的传输。同时，在此过程中，我们将可能收集您得处方、科室、医院、体检机构、检测项目、体检/就诊时间信息，用于完善用户的就诊/体检记录。如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>8. </span>健康日记功能</p>
    <p>用于记录用户每天的身体状况，方便医生的跟踪随访，当您在使用健康日记功能时，我们需要您向我们授权拍摄并读取相册、读取外部存储及写入外部存储的相关权限，用于完善用户医疗记录相关资料的传输。如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>9. </span>安全运行</p>
    <p><span>1.9.1 </span>安全保障功能我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务是我们的核心目标。读、写入外部存储，唯一设备标识符为实现安全运行所收集的必要权限和信息。如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>1.9.2 </span>设备信息与日志信息</p>
    <p><span>a. </span>为了保障软件与服务的安全运行，我们会收集您的硬件型号、android_id、操作系统版本号、国际移动设备识别码（SIM卡序列号）、SIM卡运营商、唯一设备标识符（IMSI、IMEI、）、网络设备硬件地址、IP
    地址、Mac地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。</p>
     
    <p><span>b. </span>为了预防恶意程序及安全运营所必需，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。</p>
    <p><span>c. </span>我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联公司、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。</p>
    <p><span>d. </span>我们会收集您的（基于GPS和网络）位置信息（我们仅收集您当时所处的地理位置，但不会用以判断您的行踪轨迹）来判断您所处的地点，以自动为您推荐您所在区域可以提供的在线医疗服务。</p>
    <p><span>e. </span>如果您不提供上述信息，将会影响您使用此功能。</p>
    <p><span>f. </span>当您需要复制粘贴信息至“宏康随访”APP时，需要申请您的剪切板权限，获得您粘贴的信息。</p>
    <p>当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</p>
    <p>当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
    <p>但以下情形中除外：</p>
    <p><span>a) </span>与个人信息控制者履行法律法规规定的义务相关的；</p>
    <p><span>b) </span>与国家安全、国防安全直接相关的；</p>
    <p><span>c) </span>与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p><span>d) </span>与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
    <p><span>e) </span>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
    <p><span>f) </span>所涉及的个人信息是个人信息主体自行向社会公众公开的；</p>
    <p><span>g) </span>根据个人信息主体要求签订和履行合同所必需的；</p>
    <p><span>h) </span>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p><span>i) </span>维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。</p>

    <h4>2、我们如何使用Cookie等技术</h4>
    <p>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的登录状态等数据，提升服务/产品质量及优化用户体验。我们不会将Cookie用于本政策所述目的之外的任何用途。</p>
    <h4>3、我们如何共享、转让、公开披露您的个人信息</h4>
    <p><span>3.1 </span>共享</p>
    <p>我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
    <p><span>3.1.1 </span>在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
    <p><span>3.1.2 </span>我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
    <p><span>3.1.3 </span>我们可能会将您的个人信息与我们的附属公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。附属公司如要改变个人信息的处理目的，将再次征求您的授权同意。</p>
    <p><span>3.1.4 </span></p>
    <p>与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
    目前，我们的授权合作伙伴包括以下类型：</p>
    
    <p><span>1）</span>广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的人口统计信息（例如“位于北京的25岁男性，喜欢软件开发”），帮助他们了解其受众或顾客。</p>
    <p><span>2）</span>供应商、服务提供商和其他合作伙伴。我们将信息发送给在全球范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</p>
    <p><span>3.2 </span>转让</p>
    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p><span>3.2.1 </span>在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
    <p><span>3.2.2 </span>在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
    <p><span>3.3 </span>公开披露</p>
    <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
    <p><span>3.3.1 </span>获得您明确同意后；</p>
    <p><span>3.3.2 </span>基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，主要包括以下情景：</p>
    <p><span>a. </span>与国家安全、国防安全直接相关的；</p>
    <p><span>b. </span>与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p><span>c. </span>与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
    <p><span>d. </span>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p><span>e. </span>所收集的您的个人信息是您自行向社会公众公开的；</p>
    <p><span>f. </span>从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p><span>g. </span>与我们履行法律法规规定的义务相关的；</p>
    <p><span>h. </span>法律法规规定的其他情形。</p>
    <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>

    <p><span>3.4 </span>连接其他网站的链接</p>
    <p>我们的服务可能包含连接由第三方拥有或运营的网站及服务（“第三方服务”）的链接。您通过这些链接向第三方服务提供的或由第三方收集的任何信息将直接提供给第三方服务所有人或经营者，且须遵守该等所有人或经营者的《隐私政策》或相关的条款。我们对任何第三方服务的内容、隐私或安全措施不承担任何责任。为保护您的信息安全，我们建议您仔细阅读您访问的所有第三方服务的《隐私政策》或相关条款。</p>
    <p><span>3.5 </span>第三方账户的关联</p>
    <p>当您通过我们的APP使用第三方提供的服务时，如果您在该第三方也通过手机号码注册了账户，为了您使用服务的便利，我们可能会依据您的手机号码将您在该第三方的账户与您的宏康随访账户相关联，并从该第三方平台获取您的公开信息，以方便您对相关服务的使用。该账户关联时，我们会对您的手机号码进行加密处理，不会必然导致您的个人信息在我们与该第三方之间共享。</p>
    <h4>4、我们如何存储和保护您的个人信息</h4>
    <p><span>4.1 </span>存储地点</p>
    <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将遵循相关国家规定或者征求您的同意。</p>
    <p><span>4.2 </span>存储期限</p>
    <p>我们仅在为提供“宏康随访”APP及服务之目的所必须的期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
    <p><span>4.3 </span>技术措施与数据保护措施</p>
    <p>我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受SSL 加密保护；我们同时对“宏康随访”APP提供 https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
    <p><span>4.4 </span>安全认证</p>
    <p>我们已经取得了以下认证：国家信息安全等级保护（三级）的评测和备案</p>
    <p><span>4.5 </span>安全事件处理</p>
    <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
    <h4>5、您的权利</h4>
    <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
    <p><span>5.1 </span>访问您的个人信息</p>
    <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</p>
    <p>账户信息——如果您希望访问或编辑您的账户中的头像、昵称、手机号等，您可以通过访问“宏康随访”APP—>我的—>个人资料进行查看。</p>
    <p>搜索信息——您可以在病历夹和在一起中查看历史搜索记录。</p>
    <p>对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，可通过本政策第8条提供的联系方式与我们联系。</p>

    <p><span>5.2 </span>更正您的个人信息</p>
    <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过5.1访问您的个人信息”中罗列的方式提出更正申请。如果您无法通过上述链接更正这些个人信息，您可以通过本政策第8条提供的联系方式与我们联系。</p>
    <p><span>5.3 </span>删除您的个人信息</p>
    <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
    <p><span>1、</span>如果我们处理个人信息的行为违反法律法规；</p>
    <p><span>2、</span>如果我们收集、使用您的个人信息，却未征得您的同意；</p>
    <p><span>3、</span>如果我们处理个人信息的行为违反了与您的约定；</p>
    <p><span>4、</span>如果您不再使用我们的产品或服务，或您注销了账号；</p>
    <p><span>5、</span>如果我们不再为您提供产品或服务。</p>
    <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
    <p>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。如果您想行使数据删除权，可通过本政策第8条提供的联系方式与我们联系。</p>
    
    <p><span>5.4 </span>个人信息主体获取个人信息副本</p>
    <p>您有权获取您的个人信息副本，可通过本政策第8条提供的联系方式与我们联系获得个人信息副本。</p>
    <p>在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。</p>
    <p><span>5.5 </span>约束信息系统自动决策</p>
    <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</p>
    <p><span>5.6 </span>响应您的上述请求</p>
    <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。</p>
    <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。在以下情形中，我们将无法响应您的请求：</p>

    <p><span>1．</span>与个人信息控制者履行法律法规规定的义务相关的；</p>
    <p><span>2．</span>与国家安全、国防安全直接相关的；</p>
    <p><span>3．</span>与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p><span>4．</span>与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
    <p><span>5．</span>个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
    <p><span>6．</span>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p><span>7．</span>响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
    <p><span>8．</span>涉及商业秘密的。</p>
    <p><span>9．</span>若我们决定不响应您的请求，我们将向您告知该决定的理由，您也可通过本政策第8条提供的联系方式进行投诉。</p>
    <h4>6、我们如何保护未成年人的信息</h4>
    <p>我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。尽管不同地区对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>
    <h4>7、本政策如何更新</h4>
    <p>我们可能会不时对本《隐私政策》进行修订，当本《隐私政策》发生修订时，我们会在版本更新后以推送通知、弹窗等形式向您展示变更后的内容。请您注意，在《隐私协议》变更后，我们将无法保证按照其变更前的内容收集、使用、处理和存储您的个人信息。只有在您确认修订后的《隐私政策》后，我们才会按照修订后的《隐私政策》收集、使用、处理和存储您的个人信息。因此，如果您不同意修订后的《隐私政策》，请您停止使用我们的服务。您继续使用我们服务的行为也同样会被视为您同意并接受我们修订后的《隐私政策》。</p>
    <p>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）。本政策所指的重大变更包括：</p>
    <p><span>1、</span>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
    <p><span>2、</span>我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
    <p><span>3、</span>个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p><span>4、</span>您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p><span>5、</span>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
    <p><span>6、</span>个人信息安全影响评估报告表明存在高风险时。</p>
    <p>我们还会将本政策的旧版本存档，供您查阅。</p>
    <h4>8、如何联系我们</h4>
<p>我们已经设立了个人信息保护负责人员，如果您对本隐私政策有任何疑问、意见、投诉或建议，通过以下方式与我们联系：</p>
    <h4 style="text-align:center;font-size:0.16rem;margin:0.1rem 0">电话：400-919-9800</h4>
    
    <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>

  </div>
</template>

<script>
export default {};
</script>

<style>
.box5 {
  width: 3.45rem;
  font-size: 0.10rem;
  margin: 0.1rem auto;
  box-sizing: border-box;
  line-height: 0.14rem;
}
p{
  text-indent: 0.20rem;
}

</style>