<template>
  <div id="pageone">
    <div id="echarts_zhixian" ref="echarts_dom" v-show="!noshuju"></div>
    <!-- <div class="zanwushuju" v-if="isshuju" style="text-align:center;"></div> -->
    <div class="noshuju" v-show="noshuju">
      <img src="../assets/zhexiannoshuju.png" alt="" />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
import Qs from "qs"; //引入qs
let Base64 = require("js-base64").Base64;

export default {
  name: "pageone",
  components: {},
  data() {
    return {
      title: "",
      riqi_data: [],
      // 纵坐标
      Ordinate: [],
      device_id: "",
      token: "",
      // 单位
      Company: "ug/m³",
      // isshuju:false
      noshuju: false,
    };
  },

  created() {
    // console.log('进入ios折线图页面')
    let type = this.getQueryVariable("type");
    let mid_token = Base64.decode(this.getQueryVariable("token"));
    this.token = Base64.decode(mid_token.substring(1, mid_token.length - 1));
    let device_id = this.getQueryVariable("device_id");
    // console.log(device_id, "device_id");
    this.device_id = Number(device_id);
    if (type == "formaldehyde") {
      this.title = "甲醛";
      this.Company = "mg/m³";
    } else if (type == "tvoc") {
      this.title = "TVOC";
      this.Company = "mg/m³";
    } else if (type == "pm25") {
      this.title = "PM25";
      this.Company = "ug/m³";
    } else if (type == "temperature") {
      this.title = "温度";
      this.Company = " ℃";
    } else {
      this.title = "湿度";
      this.Company = " %RH";
    }
    // console.log('进入ios折线图页面，当前想要获得的数据是' + this.title)

    // 发送请求
    this.getData();
    // }
  },

  methods: {
    // 处理url
    getQueryVariable(variable) {
      // 获取 ? 后的url 带 ?
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },


    // 绘制曲线图
    showecharts() {
      var myChart = echarts.init(this.$refs.echarts_dom);
      // let goOutSchool = ;
      let Ordinate = this.Ordinate;
      // let Ordinate = [20,23,14,57,89,54,14]
      var option = {
        title: {
          left: "center",
          text: this.title + " 今日数据",
        },
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          backgroundColor: "transparent",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(15, 22, 66,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(251,157,68,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(251,157,68,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },

        grid: {
          // top: '18%',
          left: "12%",
          right: "5%",
          // bottom: '15%',
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "#aaa",
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#7ec7ff",
                padding: 16,
                fontSize: 14,
              },
              formatter: function (data) {
                return data;
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
              },
            },
            axisTick: {
              show: false,
            },
            data: this.riqi_data,
          },
        ],
        yAxis: [
          {
            name: this.Company,
            nameTextStyle: {
              color: "#7ec7ff",
              fontSize: 16,
              padding: 10,
            },
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#aaa",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: this.title + "",
            type: "line",
            symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 1,
                color: "rgba(62,158,104,.1)", // 线条颜色
              },
              borderColor: "rgba(0,0,0,.4)",
            },
            itemStyle: {
              color: "#000",
              borderColor: "#646ace",
              borderWidth: 1,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgb(62,158,104)",
                    },
                    {
                      offset: 1,
                      color: "#c9e8d6",
                    },
                  ],
                  false
                ),
                // shadowColor: "rgba(251,157,68, 0.5)", //阴影颜色
                // shadowBlur: 10, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: Ordinate,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 处理横坐标日期:从0:00开始到23:59结束，一分钟一个，60分钟加一小时
    get_drd_data() {
      let min = 0;
      let hour = -1;
      let da_x_arr = [];

      for (let a = 0; a < 24; a++) {
        hour = hour + 1;
        for (let i = 0; i < 60; i++) {
          min = i;
          da_x_arr.push(
            (hour < 10 ? "0" + hour : hour) + ":" + (min < 10 ? "0" + min : min)
          );
        }
      }
      // 一天24小时，分成分钟
      return da_x_arr;
    },

    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let MIN =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return H + ":" + MIN;
    },

    get_start_and_end(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      return H;
    },

    // 得到数据
    getData() {
      let dataType = 0;
      if (this.title == "甲醛") {
        dataType = 1;
      } else if (this.title == "TVOC") {
        dataType = 1;
      } else if (this.title == "PM25") {
        dataType = 2;
      } else if (this.title == "温度") {
        dataType = 3;
      } else {
        dataType = 4;
      }
      let query = {
        device_id: this.device_id,
        authorization: this.token,
        dataType: dataType,
      };
      // console.log(query, "query");
      // console.log('执行获取数据')

      axios({
        method: "post",
        url: "http://www.e-rijiben.com:8001/app/getLineChart",
        data: Qs.stringify(query),
        headers: {
          token: this.token,
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          // console.log(res.data, "获取数据res.data");
          if (res.data.data.length < 1) {
            this.noshuju = true;
            return;
          }
          let x_arr = [];
          let y_arr = [];
          if (res.data.code == 200) {
            if (res.data.data.length > 0) {
              // 第一个时间
              let time_arr = [];
              let first_time = "";
              let last_time = "";

              res.data.data.forEach((item) => {
                // console.log(item, "item");
                item.forEach((item1) => {
                  // console.log(item1,'item1')
                  x_arr.push(this.timestampToTime(Number(item1.timed)));
                  time_arr.push(item1.timed);
                  y_arr.push(item1.valued);
                });
                y_arr.push(null, null);
              });
              // 有记录第一个时间和最后一个时间
              first_time = this.get_start_and_end(time_arr[0]);
              last_time = this.get_start_and_end(time_arr[time_arr.length - 1]);
              let fin_x_arr = [];

              // 处理第一个数据前
              if (first_time <= 2) {
                // console.log("e");
              } else {
                fin_x_arr.push("...");
                fin_x_arr.push(
                  (first_time - 1 < 10
                    ? "0" + (first_time - 1)
                    : first_time - 1) +
                    ":" +
                    "00"
                );
                fin_x_arr.push(first_time + ":" + "00");
                y_arr = [null, null, null, ...y_arr];
              }
              fin_x_arr = [...fin_x_arr, ...x_arr];
              // 处理最后一个数据后的时间
              let last_arr = [];
              if (last_time >= 23) {
                // console.log("m");
              } else {
                last_arr.push(
                  last_time + 1 < 10
                    ? "0" + (last_time + 1) + ":" + "00"
                    : last_time + 1 + ":" + "00"
                );
                last_arr.push(
                  last_time + 2 < 10
                    ? "0" + (last_time + 2) + ":" + "00"
                    : last_time + 2 + ":" + "00"
                );
                y_arr = [...y_arr, null, null, null, null];
              }
              fin_x_arr = [...fin_x_arr, ...last_arr];
              if (this.title == "甲醛") {
                // console.log(y_arr, "甲醛的纵轴");
                let formaldehydearr = [];
                y_arr.forEach((item) => {
                  item = (
                    0.0409 *
                    30.026 *
                    (Number(item) / 1000).toFixed(10)
                  ).toFixed(2);
                  formaldehydearr.push(item);
                });
                this.Ordinate = formaldehydearr;
              } else if (this.title == "TVOC") {
                let formaldehydearr = [];
                y_arr.forEach((item) => {
                  item = (
                    0.0409 *
                    78.9516 *
                    (Number(item) / 1000).toFixed(10)
                  ).toFixed(2);
                  formaldehydearr.push(item);
                });
                this.Ordinate = formaldehydearr;
              } else {
                this.Ordinate = y_arr;
              }
              this.riqi_data = fin_x_arr;
              this.showecharts();
            } else {
              console.log("没有数据");
            }
          }
        })
        .catch((error) => {
          console.log("错了：", error);
        });
    },
  },
};
</script>

<style>
.noshuju {
  box-sizing: border-box;
  width: 50%;
  height: 1.8rem;
  margin: 0.2rem auto;
  margin-top: 30%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.noshuju img {
  width: 100%;
  height: 100%;
}
#pageone {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.19rem;
  height: 100%;
}
#echarts_zhixian {
  width: 96%;
  margin: 0.1rem auto;
  height: 3.82rem;
  font-size: 0.12rem !important;
}
.van-popup {
  bottom: 0.1rem !important;
}
/* .zanwushuju{
  width: 3.55rem;
  height: 3.78rem;
  margin: 0.1rem auto;
  background: url(../assets/images/noshuju.jpg);
  background-size: 100% 100%;
} */
</style>
