<template>
  <div class="box2">

    <div style="font-size: 0.09rem;margin:0.1rem 0 0 0.2rem">
      <div>更新日期：2022年1月1日</div>
      <div>生效日期：2022年1月5日</div>
    </div>

    <h4>引言</h4>
    <p>
      您的信任对我们至关重要，我们深知个人信息对您的重要性。我们将按照法律法规要求，在合理、必要的范围内，向您收集个人信息。我们竭尽全力通过合理有效的信息安全技术和管理流程，采取相应的安全保护措施，防止您的信息泄露、损毁或丢失。鉴于此，日记本平台服务提供者（或简称“我们”）制定本《隐私政策》，并提示您注意：
    </p>
    <p>
      本协议适用于日记本平台服务提供者以网站、客户端、小程序以及随技术发展出现的新形态向您提供的所有产品和服务。本协议所称的日记本平台是指日记本（北京）健康科技有限公司及其关联公司运营的空气骑士客户端。
    </p>

    <h4>
      需要特别说明的是，本协议不适用于第三方向您提供的服务，例如通过日记本平台跳转到第三方网站、客户端或程序，您向该第三方服务商提供的个人信息不适用本协议。
    </h4>
    <p>
      在使用日记本平台各项产品或服务前，请您务必仔细阅读并透彻理解本协议，特别是加粗、加下划线标识的条款，您应重点阅读，在充分理解并同意接受后开始使用。如对本协议有任何疑问，您可向日记本客服咨询（400-919-9800）或通过日记本任一平台上提供的联系方式与我们联系。
    </p>
    <p>
      如您为未满18周岁的未成年人，请在法定监护人陪同下阅读本协议，并特别注意未成年人使用条款。
    </p>
    <p>本协议主要向您说明：</p>
    <p><span>1</span>我们的角色</p>
    <p><span>2</span>我们收集您哪些信息</p>
    <p><span>3</span>我们如何使用您的信息</p>
    <p><span>4</span>我们在何种情况下共享您的信息</p>
    <p><span>5</span>公开披露</p>
    <p><span>6</span>我们如何存储您的信息</p>
    <p><span>7</span>我们如何保护您的信息</p>
    <p><span>8</span>您所享有的权利</p>
    <p><span>9</span>您如何联系我们</p>
    <p><span>10</span>注销</p>
    <p><span>11</span>权限收集说明</p>
    <p><span>12</span>条款更新</p>

    <h4>1、我们的角色</h4>
    <p>
      我们的角色：我们是您的数据的控制者。这意味着我们有权决定如何处理您的个人信息以及用于何种目的。我们知道您关心如何使用和分享您的数据，同时我们感谢您的信任，我们将谨慎且理智地做到这一点。
    </p>
    <h4>2、我们收集您哪些信息</h4>
    <p>
      我们收集您的信息以合法、合理、必要为原则，在向您提供某项具体服务时，我们将明确告知您将收集的信息以及用途，来响应您具体的服务请求。根据您选择的服务，我们在以下场景可能收集以下信息的一种或多种：
    </p>
    <p>
      <span>2.0</span>获取设备序列号 获取Android_ID
      用于友盟移动统计SDK，统计分析组件可精准统计应用的新增、启动、活跃、自定义事件等指标，并包含定位crash错误定位功能。app自启动,仅用于当打开手机蓝牙开关后，app会自动接受广播通知，并且开始扫描在线的消毒机设备。
    </p>
    <p>
      <span>2.1</span>首次登录时，您需要提供您的手机号码来注册您的帐号，并设置您的帐号密码。您还可以通过“微信登录”等方式登录空气骑士App。如果您采取“微信登录”方式，我们还需要获取您头像、昵称。我们以此识别您在空气骑士App的身份。上述方式由您自行选择，如您不提供相关信息，日记本平台将无法为您创建帐号并提供服务。
    </p>

    <p>
      <span>2.2</span>当您的物联网设备首次联网或更换网络环境时，您需要进行设备配网流程。为了发现智能设备并连接WLAN网络，“空气骑士”应用需要访问手机的位置权限，通过访问精确位置获取WiFi列表和您输入的WLAN名称、WLAN密码。上述信息只在本地使用，不会上传至云服务器，日记本平台不会收集WiFi密码。同时方便后续绑定设备，App或小程序本地会加密保存WiFi密码，用于下次设备配网时密码自动填充，以便快速设备联网。在空气骑士App的首页和配网页面，为了能够更快的发现您身边的设备，我们会根据手机版本情况间歇性地自动扫描您附近的WiFi列表。用于设备绑定。
    </p>
    <p>
      <span>2.3</span>在您使用我们的产品与服务过程中，为识别帐号或设备的异常状态、了解或优化产品服务的适配性、提供应用程序版本升级或固件版本升级服务，我们会收集您的使用情况并存储为网络日志信息，包括相关设备的硬件型号、IESI号码、IMEI号码、ANDROID
      ID或其他唯一设备标识符、MAC地址、IP地址、操作系统版本、应用程序列表、注册日期以及用于访问服务的设备的设置。为了提供更好的智能化体验服务，我们会收集您物联网设备的相关使用情况，例如设备型号、设备运行状态、设备使用频次、电量使用情况、环境温度、环境湿度。
    </p>
    <p>
      <span>2.4</span>当您复制短信验证码，分享或接收被分享口令信息时，您的剪切板功能可能会被调用读取或写入相关信息。其中读取短信验证码时用于使用手机号和验证码的方式登录时自动填入验证码；分享或接受被分享空气骑士App口令信息时用于把口令信息写入剪切板，或监控设备其他应用分享的口令。剪切板信息仅会在您的设备上进行处理，我们不会存储您的剪切板信息用于任何其他途径。
    </p>
    <p>
      <span>2.5</span>当您开启设备或APP/小程序定位功能并使用我们基于位置提供的天气服务，我们会收集位置信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。我们会使用IP
      地址、GPS以及能够提供相关信息的其他传感器（提供附近设备、WLAN接入点和基站的信息）等技术进行定位。您可以通过系统授权关闭定位功能，停止我们对您的地理位置信息的收集，关闭后您无法使用相关功能，或者无法达到相关服务拟达到的效果。注意：收集您当前所处的位置信息是为了向您提供环境服务和快捷的设备配网服务，并不会追踪您的行踪轨迹。
    </p>
    <p>
      <span>2.6</span>若您参与日记本平台相关促销促活等各类活动，为发放和寄送相关活动奖品，我们会需要您填写/提供姓名、手机号码、地址。若您参与产品试用等类似活动，为了解客户需求以及筛选我们的目标试用客户，我们还可能会需要您填写/提供您的性别、年龄段、收入段、家庭结构、家中产品、生活偏好等信息（具体以活动页面为准）。您填写并提交相关信息，参与该活动，即视为您同意我们收集并为上述目的使用该信息。您也可以选择不参与相关活动，不提供活动所需相关信息。
    </p>
    <p>
      <span>2.7</span>为了帮助我们了解上述任一日记本平台的运行情况，我们可能会记录相关信息，例如您的使用频率、崩溃数据、总体使用情况、性能数据。我们不会将存储于分析软件的信息关联到您的任何个人信息。
    </p>
    <p>
      <span>2.8</span>需要特别注意的是，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类信息与其他信息结合用于识别特定自然人身份，或者将其与其他个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
    </p>
    <p>
      <span>2.9</span>此外，为实现在线反馈App/小程序的使用问题，我们需要收集您的以下信息：联系方式、问题描述、问题现象图片或视频。收集此类信息是了更好的分析与定位问题、与您联系获取更多信息以解决问题，并对服务情况进行追踪闭环。如您不提供此类信息，我们可能将无法对您反馈的问题进行有效的定位与解决。
    </p>
    <p>
      <span>2.10</span>我们也可能收集其他信息，例如，您参与问卷调查时向我们发送的问卷答复信息；参加用户体验计划以及您与我们互动时向我们提供的信息。
    </p>
    <p>
      <span>2.11</span>空气骑士App/小程序可能会调用您安卓设备的权限，以下是调用权限对应的业务功能、我们调用的目的，以及调用权限向您询问的情况。
    </p>
    <p>
      <span>2.12</span>若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若我们将信息用于本协议未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
    </p>
    <p><span>2.13</span>征得授权同意的例外：</p>
    <p>
      您充分理解并同意，我们在以下情况下收集、使用、共享、转让、公开披露您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
    </p>
    <p><span>2.14.1</span>与国家安全、国防安全有关的；</p>
    <p><span>2.14.2</span>与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>
      <span>2.14.3</span>与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
    </p>

    <p>
      <span>2.14.4</span>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p><span>2.14.5</span>您自行向社会公众公开的个人信息；</p>
    <p>
      <span>2.14.6</span>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p><span>2.14.7</span>根据与您签订和履行相关协议或其他书面文件所必需的；</p>
    <p>
      <span>2.14.8</span>用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；
    </p>
    <p><span>2.14.9</span>为合法的新闻报道所必需的；</p>
    <p>
      <span>2.14.10</span>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p>
    <p><span>2.14.11</span>法律法规规定的其他情形。</p>

    <h4>3、我们如何使用您的信息</h4>
    <p>
      <span>3.1</span>我们会保持您的个人数据及时更新；安全地存储或销毁它；最小化收集和保留我们需要为您提供服务所需的数据；保护个人信息免遭泄露、滥用、未经授权的访问和披露；采取适当的技术措施保护您个人信息的安全。
    </p>
    <p><span>3.2</span>我们将您的信息用于以下用途：</p>

    <p>
      <span>3.2.1</span>根据我们与您签订的合同向您提供服务（注册、购买、报装、报修、家电激活与控制等）。
    </p>
    <p>
      <span>3.2.2</span>实现您的交易或服务请求，包括：履行订单；交付、激活或验证产品或服务；应您的要求进行变更或者提供您请求的信息（例如产品或服务的营销资料、白皮书）；以及提供技术支持。
    </p>
    <p>
      <span>3.2.3</span>按照我们收集信息时所述的要求提供其他您所要求的服务。
    </p>
    <p>
      <span>3.2.4</span>维持我们储存和处理您个人信息的数据技术系统的完整性和安全性。
    </p>
    <p><span>3.2.5</span>执行或保护我们的协议或与您签订的合同。</p>
    <p><span>3.2.6</span>检测和调查信息泄露、非法活动和欺诈行为。</p>
    <p><span>3.2.7</span>联系您解决问题。</p>
    <p><span>3.2.8</span>经过您许可的其它用途。</p>
    <p>
      <span>3.3</span>需要注意的是，以上使用方式涵盖了我们使用您信息的主要方式。我们可能时不时地通过更新网站页面、App版本、小程序版本或设备固件等方式，为用户提供更加多样化的服务。当我们要将信息用于本协议未载明的其他用途时，或要将基于特定目的收集而来的信息用于其他目的时，会以签署协议、弹窗提示、站内公告、更新本协议等形式再次征求您的同意。
    </p>

    <h4>4、我们在何种情况下共享您的信息</h4>
    <p>
      我们不会与日记本以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p>
      <span>4.1</span>在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    </p>
    <p>
      <span>4.2</span>获取明确同意的情况下共享：获得您的明确同意后，我们会与第三方共享您的个人信息。
    </p>
    <p>
      <span>4.3</span>与关联公司间共享：为便于我们向您提供产品和服务，识别帐号异常，保护其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本协议中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
    </p>
    <p>
      <span>4.4</span>与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本协议声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。此类处理后数据的使用和共享我们无需另行向您通知并征得您的同意。
    </p>
    <p>目前，我们的授权合作伙伴包括以下类型：</p>

    <p>
      <span>4.4.1</span>供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
    </p>

    <p>
      当您使用空气骑士微信分享功能时需要获取您的设备信息和网络状态。
      针对第三方SDK使用，我们将基于不同步用户个人数据为前提，授权合作伙伴获取有关业务信息。同时针对第三方的SDK应用程序接口（API）,、软件工具开发包（SDK）我们会进行严格的安全检测，并与合作伙伴签订严格的数据保护承诺/协议，令其按照我们的委托目的、服务说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p>
      <span>4.5</span>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </p>
    <p>
      <span>4.5.1</span>在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
    </p>
    <p>
      <span>4.5.2</span>在日记本平台服务提供者发生合并、内外部业务重组、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
    </p>
    <h4>5、公开披露</h4>
    <p>
      若您使用手机号首次登录空气骑士APP和空气骑士Lite微信小程序，您的帐号昵称默认为您的手机号。
    </p>
    <p>除上述情况外，我们仅仅会在以下情况公开披露您的个人信息：</p>
    <p><span>5.1</span>获得您的同意后。</p>
    <p>
      <span>5.2</span>基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
    </p>
    <h4>6、我们如何存储您的信息</h4>
    <p>
      按照法律规定，我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内，我们不会跨境传输或跨境存储您的个人数据。
    </p>
    <p>除非法律有另外规定，日记本平台将按照 如下期限保存您的信息：</p>
    <p>
      <span>6.1</span>在您使用日记本平台产品或服务期间，我们将持续为您保存。
    </p>
    <p>
      <span>6.2</span>我们将根据日记本平台的数据备份策略定期对收集的信息进行备份处理。
    </p>
    <p>
      <span>6.3</span>您的个人信息经过匿名化与脱敏处理后将形成可以使用的流通数据，日记本平台对此数据的保存和使用无需另外征得您的同意。
    </p>
    <p>
      <span>6.4</span>当我们的产品或服务停止运营的情形时，我们将于推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或匿名化处理。
    </p>
    <h4>7、我们如何保护您的信息</h4>
    <p>
      <span>7.1</span>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对日记本平台各网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p>
      <span>7.2</span>互联网并非绝对安全的环境，使用日记本平台产品或服务时，我们强烈建议您不要使用非日记本平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
    </p>
    <p>
      <span>7.3</span>在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p>
      <span>7.4</span>您充分理解并同意，我们有权审查用户注册所提供的身份信息是否真实、有效，并积极采取技术与管理等合理措施保障用户帐号的安全、有效；您有义务妥善保管其帐号及密码，并正确、安全地使用其帐号及密码。任何一方未尽上述义务导致帐号密码遗失、帐号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。
    </p>
    <p>
      <span>7.5</span>若您发现有他人冒用或盗用您的空气骑士帐号及密码、或任何其他未经您合法授权使用的情形时，应立即以要求的有效方式通知并告知我们。您通知时，应提供与您注册身份信息相一致的个人有效身份信息，收到您的有效请求并核实身份后，会根据您的要求或结合具体情况采取相应措施（包括但不限于暂停该帐号的登录和使用，解绑删除帐号绑定的智能设备等），因根据您的请求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，我们有权拒绝您的请求，因此而造成的损失，由您自行承担。
    </p>
    <h4>8、您所享有的权利</h4>
    <p>您有权利管理您的信息，包括：</p>
    <p><span>8.1</span>访问您的个人信息：</p>
    <p>
      <span>8.1.1</span>帐号信息：如果您希望访问或编辑您的帐号中的个人资料信息、更改您的密码等，您可以登录您的帐号后通过设置等进行访问或编辑。
    </p>
    <p>
      <span>8.1.2</span>其它个人信息（例如售后、维修等服务时）：对于您使用我们的产品或服务过程中产生的个人信息；
    </p>
    <p><span>8.2</span>更正您的个人信息</p>
    <p>
      当您发现我们处理您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过访问个人信息设置页面进行更正或补充说明或者直接联系我们。
    </p>
    <p><span>8.3</span>注销您的个人信息</p>
    <p>在下列情况中，您可以联系日记本平台客服提出注销个人信息请求：</p>
    <p><span>8.3.1</span>如果我们处理个人信息的行为违反法律法规。</p>
    <p>
      <span>8.3.2</span>如果我们收集、使用您的个人信息，却未征得您的明确同意。
    </p>
    <p><span>8.3.3</span>如果我们处理个人信息的行为严重违反了与您的约定。</p>
    <p><span>8.3.4</span>如果永久不需要我们提供产品或服务。</p>
    <p>
      <span>8.3.5</span>如果我们与他人共享或转让您的个人信息，却未征得您的明确同意，您有权要求我们及第三方删除。
    </p>
    <p>
      <span>8.3.6</span>如果我们违反与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
    </p>
    <p>
      如果我们决定响应您的删除请求，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息。
    </p>
    <h4>9、您如何联系我们</h4>
    <p>
      <span>9.1</span>如果您对本隐私协议有任何疑问、意见或建议，或者您想要行使任何权利，或者您有任何要求要与我们讨论，请通过以下方式与我们处理个人信息保护事宜的部门联系：
    </p>

    <p>
      <span>9.1.1</span>日记本平台服务提供者：日记本（北京）健康科技有限公司
    </p>
    <p>
      <span>9.1.2</span>地址：北京市北京经济技术开发区科创十三街18号院8号楼401
    </p>
    <p><span>9.1.3</span>电子邮件：lipeng@e-rijiben.com</p>
    <h4>10、注销</h4>
    <p><span>10.1</span>我们为您提供用户注销功能。在您已解绑所有设备的情况下，如有需要，可以前往 “我的”编辑个人信息功能里注销您当前的账户。注销之后无法恢复，请您谨慎操作。</p>

    <h4>11、权限收集说明</h4>
    <p>
      <span>11.1</span>获取定位权限：允许应用能获取用户的位置，以便显示当前位置的天气情况。
    </p>
    <p>
      <span>11.2</span>使用相机权限：允许应用在用户使用时通过扫码连接消毒机设备。
    </p>
    <p>
      <span>11.3</span> SD卡读写权限：用于QQ等第三方分享消毒机的设备数据信息。
    </p>
    <p><span>11.4</span>手机设备权限：用于友盟第三方统计，推送信息。</p>
    <p><span>11.5</span>蓝牙权限：用于搜索连接低功耗蓝牙设备。</p>
    <p><span>11.6</span>集成 新浪微博SDK com.sina.weibo用于后期分享消毒报告。</p>
    <p><span>11.7</span>集成高德SDK com.amap.api用于 GPS定位用户并且显示附近消毒机。</p>
    <p><span>11.8</span>集成的友盟sdk会获取ICCID,IMSI个人信息，用于对手机报错信息收集反馈。</p>

    <h4>12、条款更新</h4>
    <p>
      <span>12.1</span>为向您提供更好的服务和更优质的用户体验，本协议会根据业务调整、法律法规或政策的变化而发生适时变更。如您在隐私政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私政策并受其约束。
    </p>
    <p style="text-align: center">日记本（北京）健康科技有限公司</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box2 {
  width: 3.45rem;
  font-size: 0.16rem;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 0.26rem;
}

p,
h4,
h3 {
  margin-bottom: 0.1rem;
}

h4,
h3 {
  font-size: 0.14rem;
  color: rgb(42, 40, 40);
}

p {
  font-size: 0.12rem;
  color: rgb(59, 59, 59);
  text-indent: unset;
}
h3 {
  text-align: center;
}
span {
  /* display: block; */
  font-weight: 600;
  padding-right: 0.08rem;
}
/* p {
  text-indent: 0.24rem;
} */
.box2 .aaaaa i {
  display: flex;
  font-style: unset;
}
</style>