import axios from 'axios';
import qs from 'qs'
import baseURL from '../api/baseUrl'

const service = axios.create({
  // baseURL:"http://www.e-rijiben.com:8001",//正式地址，打包前打开并将反向代理去掉
  baseURL,
  timeout: 5000,
});
// 请求拦截器
service.interceptors.request.use(
  config => {
    if(sessionStorage.getItem('anzhuorestreporttoken')){
      config.headers.token = sessionStorage.getItem('anzhuorestreporttoken')
    }
    // console.log(config,'config')
    // 判断自定义请求类型：CustomRequestType，true：json类型 
    if(config.headers.CustomRequestType){
      config.data
    }else {
      config.data = qs.stringify(config.data)// 转为formdata数据格式
    }
    
    // return
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject();
  }
);
// 响应拦截器  
service.interceptors.response.use(
  response => {
    // console.log(response,'response')
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  error => {
    console.log(error,'error');
    return Promise.reject();
  }
);

export default service;
