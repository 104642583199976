<template>
  <div style="width: 100%">
    <p class="new_title">{{ news.essay.title }}</p>

    <!-- <div class="new_jieshao">
      <img :src= "news.essay.iconImg" v-if="news.essay.iconImg"/>
      <img src="../assets/tou.png" alt="" v-else>

      <div class="new_box">
        <div class="new_box_1">
          <span>{{ news.essay.writer==""?"佚名":news.essay.writer }}</span
          ><span>{{ news.essay.writerPost==""?"暂时未知":news.essay.writer }}</span>
        </div>
        <div class="new_box_2">{{ news.essay.writerHospital==""?"暂时未知":news.essay.writer }}</div>
      </div>
    </div> -->

    <div class="new_con">
      <div
        v-html="news.essay.content"
        style="font-size: 0.16rem; color: #4a4a4a"
      ></div>
    </div>

    <div class="new_end">— End —</div>

    <div class="new_footer">
      <div class="news_divs">
        浏览量
        <span>{{ news.essay.view }}</span>
      </div>
    </div>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
import { get_newsdet, addViewNum } from "../api/index";

export default {
  data() {
    return {
      news: {
        essay: {
          title: "",
          writer: "",
          writerPost: "",
          writerHospital: "",
          content: "",
          div: "",
          view: "",
        },
      },
      token: "",
      news_id: "",
    };
  },

  mounted() {
    let from = this.getQueryVariable("from");
    let str = this.getQueryVariable("token");
    // console.log(from,'from')
    if (from) {
      this.token = Base64.decode(
        Base64.decode(str).substring(2, Base64.decode(str).length - 2)
      ).replace(/\+/g, "-");
    } else {
      this.token = Base64.decode(
        Base64.decode(str).substring(1, Base64.decode(str).length - 1)
      ).replace(/\+/g, "-");
    }
    // console.log(this.token)
    // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjY4LCJ1c2VybmFtZSI6IjE1MDI5MTM5MDIwIn0.6_Kxk99CBGwpR9cjDfJeMxRcWZBQlJ600rVspqnCTUo'
    this.news_id = this.getQueryVariable("id");
    // 拿到文章ID发送请求获取文章
    let query = {
      essayId: this.news_id,
    };
    this.get_news_det(query);
    // 已经看过
    // this.readed(query);
  },

  methods: {
    // 处理url
    getQueryVariable(variable) {
      // 获取 ? 后的url 带 ?
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      // console.log(vars,'vars')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          if (pair[3] == "" || pair[4] == "") {
            return `${pair[1]}==`;
          } else {
            return pair[1];
          }
        }
      }
      return false;
    },
    // 已经看过
    readed(query) {
      addViewNum(query)
        .then(() => {})
        .catch({});
    },
    // 获取新闻详情
    get_news_det(query) {
      get_newsdet(query)
        .then((res) => {
          console.log(res, "get_new_det");
          if (res.code == 200) {
            if (res.data == null || res.data == "" || res.data == undefined) {
              return;
            } else {
              if (
                res.data.content == null ||
                res.data.content == "" ||
                res.data.content == undefined
              ) {
                return;
              } else {
                // console.log(res.data.content.replace(/<p><img/g,'<p><img class="fuzhiimg"'),'content')
                res.data.content = res.data.content.replace(
                  /<p><img/g,
                  '<p><img class="fuzhiimg"'
                );
                // res.data.content = res.data.content.replace(/<p><img/g,'<p><br/><img class="fuzhiimg"')
                this.news.essay = res.data;
              }
            }
          }
        })
        .catch({});
    },
  },
};
</script>

<style>
.fuzhiimg {
  /* margin-left: -0.2rem !important; */
  border-radius: 0.06rem;
  margin: 0.15rem 0 0.1rem;
}
.new_title {
  width: 3.35rem;
  margin: 0.2rem auto;
  text-indent: unset;
  padding: 0.05rem 0;
  font-size: 0.21rem;
  font-weight: 700;
}

.new_jieshao {
  width: 3.35rem;
  height: 0.44rem;
  display: flex;
  align-items: center;
  margin: 0.1rem auto;
}

.new_jieshao img {
  display: inline-block;
  width: 0.44rem;
  height: 0.44rem;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 0.08rem;
  box-sizing: border-box;
}

.new_box {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}
.new_box_1 {
  font-size: 0.14rem;
  color: #4a4a4a;
}
.new_box_1 span:nth-of-type(1) {
  font-size: 0.16rem;
  font-weight: 500;
  color: #4a4a4a;
}
.new_box_1 span:nth-of-type(2) {
  font-weight: 400;
  color: #4a4a4a;
  font-size: 0.12rem;
  margin-left: 0.03rem;
}

.new_box_2 {
  font-size: 0.12rem;
  color: #4a4a4a;
}

.new_con {
  width: 3.35rem;
  box-sizing: border-box;
  margin: 0.1rem auto;
}

.new_con p {
  width: 3.35rem;
  /* word-break: break-all;
  word-wrap: break-word !important; */
  text-indent: unset;
  line-height: 0.28rem;

  word-wrap: break-word;
  /* overflow: hidden; */
  text-align: justify;
  word-break: break-all;
  text-justify: distribute;
}

.new_end {
  width: 0.64rem;
  height: 0.2rem;
  font-size: 0.14rem;
  color: #929292;
  margin: 0.32rem auto;
}

.new_footer {
  width: 3.35rem;
  margin: 0rem auto;
  height: 0.6rem;
  line-height: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.12rem;
  color: #929292;
}
</style>
