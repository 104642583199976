<template>
  <div class="h5-bg">
    <div class="top">
      <div class="head fb">
        <div class="hd-l">
          <div class="hdl-one">
            <span class="hdl-logo">
              <img :src="reportList.icon" alt="" /> </span
            >&nbsp; 病毒检测报告
          </div>
          <div class="hdl-two">——{{ reportList.name }}</div>
        </div>
        <div class="hd-r">
          <img src="../assets/xijun.png" alt="" />
        </div>
      </div>
    </div>

    <!--  -->
    <div class="banner">
      <span>去除率（%）</span>
      <span class="b-sign">{{reportList.reportResult }}</span>
    </div>
    <div class="pics" v-for="(item, index) in reportList.images" :key="index">
      <img :src="item" alt="" />
    </div>
  </div>
</template>

<script>
import { get_checkreport } from "../api/index";
// import { ElMessage } from "element-plus";
// import { Base64 } from "js-base64";
export default {
  data() {
    return {
      reportList: {
        reportResult:0
      },
      id: "",
      dataType: "",
      token: "",
    };
  },
  mounted() {
    // let Base64 = Base64;
    // let Base64 = require("js-base64").Base64;
    // let from = this.getQueryVariable("from");
    // let str = this.getQueryVariable("token");
    // // console.log(from,'from')
    // if (from) {
    //   this.token = Base64.decode(
    //     Base64.decode(str).substring(2, Base64.decode(str).length - 2)
    //   ).replace(/\+/g, "-");
    // } else {
    //   this.token = Base64.decode(
    //     Base64.decode(str).substring(1, Base64.decode(str).length - 1)
    //   ).replace(/\+/g, "-");
    // }
    // sessionStorage.setItem("anzhuorestreporttoken", this.token);
    // sessionStorage.setItem("token", this.getQueryVariable("token"));
    //  console.log(this.getQueryVariable('token'),"token");
    this.id = this.getQueryVariable("id");
    this.dataType = this.getQueryVariable("dataType");
    this.getReportMsg();
  },
  methods: {
    async getReportMsg() {
      let query = {
        dataType: this.dataType,
      };
      // console.log(query, "query");
      get_checkreport(query).then((res) => {
        // console.log(res, "jdxehj");
        // ElMessage.error("获取失败");
        if (res.code !== 200) return "";
        res.data.forEach((item) => {
          // console.log(item, "item");
          if (item.id == this.id) {
            item.images = item.images.split(",");
            this.reportList = item;
            // console.log(this.reportList, "uuu");
          }
        });
      });
    },
    // 处理url
    getQueryVariable(variable) {
      // 截取 '?' 后的url 带 '?'
      var query = window.location.href.split("?")[1];
      // console.log(query, "qyyy");
      var vars = query.split("&");
      // console.log(vars, "sss");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.fb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hd-r img {
  width: 100%;
  height: 100%;
}
.hd-r {
  font-size: 0;
}
/* .hd-r  */

.h5-bg::-webkit-scrollbar {
  display: none;
}

.h5-bg {
  height: 100%;
  overflow-y: auto;
}
.top {
  width: 100%;
  box-sizing: border-box;
  background: url(../assets/h5-bg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 0.17rem;
  margin-bottom: 0.07rem;
}
.head {
  width: 100%;
  height: 0.89rem;
  color: #004924;
  padding: 0 0.15rem;
  box-sizing: border-box;
}
.hdl-one {
  width: 2.52rem;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  line-height: 0.3rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium, PingFang SC;
}
.hdl-logo {
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
}
.hdl-logo img {
  width: 100%;
  height: 100%;
}
.hdl-two {
  margin-top: 10px;
  text-align: right;
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
/*.hdl-logo,
.pics {
  background-color: rgb(16, 157, 182);
} */
.hd-r {
  width: 0.89rem;
  height: 0.89rem;
}

.banner {
  margin: 0 auto;
  width: 3.45rem;
  height: 0.72rem;
  box-sizing: border-box;
  padding: 0 0.13rem;
  background: #e6ede9;
  border-radius: 0.14rem;
  border: 2px solid #296647;
  font-size: 0.2rem;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  color: #004924;
  line-height: 0.6rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b-sign {
  font-size: 0.43rem;
  line-height: 0.6rem;
}
.pics {
  margin: 0 auto;
  width: calc(100% - 0.15rem);
  height: 4.88rem;
  margin-bottom: 0.2rem;
}
.pics img {
  width: 100%;
}
</style>
