<template>
  <div class="box3">
    <h4 style="text-align: center; margin-top: 0.1rem">
      空气骑士APP软件V1.1.0--使用手册
    </h4>
    <h3>一、软件用途</h3>
    <p>
      空气骑士APP是移动端产品用来连接空气骑士的一个桥梁，用于显示空气骑士的实时数据，便于用户可以非常直观的看到空气骑士的工作。
    </p>

    <h3>二、空气骑士APP使用方法</h3>
    <h4>1. 用户注册及登录</h4>
    <p>
      （1）从手机应用商店下载并安装，安装成功后，打开APP，可以非常直观的看到有两种登录/注册方式，分别是密码登录、短信验证码登录，如图1所示，对于第一次登录的用户，我们默认的是登录并注册；
    </p>

    <viewer>
      <img src="../assets/tu1.png" alt="" title="点击放大" />
    </viewer>
    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图1 登录/注册页面
    </p>

    <p>
      （2）我们提供中/英文两个版本，可实时切换，便于服务不同语言的用户。如图2所示
    </p>

    <viewer><img class="build_matrix_box" src="../assets/tu2.jpg" /> </viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图2 英文版登录界面
    </p>

    <h4>2. 空气骑士的绑定</h4>
    <p>当登录成功后，会让您选择您要绑定空气骑士类别</p>
    <p>
      （1）等离子体空气消毒机是通过蓝牙连接绑定的，选定等离子体空气消毒机点击下一步，绑定流程如图3所示
    </p>
    <viewer> <img class="build_matrix_box" src="../assets/tu3.png" /></viewer>
    <viewer> <img class="build_matrix_box" src="../assets/tu4.png" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图3 等离子体空气消毒机绑定流程
    </p>

    <p>
      （2）水离子空气消毒机是通过扫码绑定的，选定便携式消毒机点击下一步。绑定流程如图4
      所示
    </p>

    <viewer> <img class="build_matrix_box" src="../assets/tu5.png" /></viewer>

    <p style="text-align: center; font-size: 0.1rem">
      图4 便携式消毒机绑定流程
    </p>

    <h3>三、页面展示</h3>
    <h4>1. 设备列表的两种布局方式：块状布局和横向布局，如图5</h4>
    <p>块状布局</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu55.png" /></viewer>
    <p>横向布局</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu54.png" /></viewer>


    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图5 设备列表
    </p>

    <h4>
      2. 等离子体空气消毒机各个指标（设备名称、消杀率、甲醛、TVOC、温度、湿度、消毒报告等）展示以及对设备操作的入口，如图6
    </h4>

    <viewer> <img class="build_matrix_box" src="../assets/tu57.jpg" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图6 指标展示图及操作入口
    </p>

    <h4>
      3.等离子体空气消毒机的操作，如定时开关、工作模式等，如图7
    </h4>
    <p>定时开关机</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu8.png" /></viewer>
    <p>工作模式</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu56.jpg" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图7 各个详情展示
    </p>

    <h4>4. 水离子空气消毒机各个指标（设备名称、消杀率、甲醛、TVOC、温度、湿度、消毒报告等）展示和生成消毒报告的入口等，如图8</h4>
    <viewer> <img class="build_matrix_box" src="../assets/tu57.jpg" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图8 水离子空气消毒机
    </p>

    <h5>4.1 两种机型生成的消毒报告的分享 如图8.1</h5>
    <viewer> <img class="build_matrix_box" src="../assets/tu22.png" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图8.1 生成消毒报告的分享
    </p>

    <h4>5. 福利中心，在这里您可以进行打卡和浏览新闻资讯等操作，打卡可以获取相应的积分，积分可以用来兑换勋章。如图9</h4>

    <viewer> <img class="build_matrix_box" src="../assets/tu11.png" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图9 福利中心
    </p>

    <p>兑换勋章</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu20.png" /></viewer>
    <p>已兑换勋章</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu13.png" /></viewer>
    <p>新闻详情</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu12.png" /></viewer>


    <h4>6. 我的界面展示，在‘我的’页面里，有个人资料、积分任务、我的设备、用户反馈、操作手册、关于我们、设置、语言切换的入口。 如图10</h4>

    <viewer> <img class="build_matrix_box" src="../assets/tu58.jpg" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图10 我的界面
    </p>

    <h4>
      7.
      我的列表式功能入口展示，如图11
    </h4>

    <p>（1）个人资料</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu15.png" /></viewer>

    <p>（2）积分任务</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu19.png" /></viewer>

    <p>（3）我的设备</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu23.png" /></viewer>

    <p>（4）用户反馈、关于我们、设置、语言</p>
    <viewer> <img class="build_matrix_box" src="../assets/tu11.jpg" /></viewer>

    <p style="text-align: center; font-size: 0.1rem; text-indent: 0">
      图11 各个详情展示
    </p>

    <p
      style="
        text-align: center;
        font-size: 0.12rem;
        text-indent: 0;
        color: #4a4a4a;
        height: 0.3rem;
        line-height: 0.3rem;
      "
    >
      最后，感谢您耐心的阅读，若有疑问之处请联系我们的工作人员~
    </p>
  </div>
</template>

<script>

export default {
  data() {
    return {
      a: 1,
      initA: 1,
    };
  },

  methods: {
 
  },
  mounted() {
    
  },
};
</script>

<style scoped>
.box3 {
  width: 3.45rem;
  font-size: 0.16rem;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 0.26rem;
}
h3,
h4 {
  margin-bottom: 0.08rem;
  font-size: 0.14rem;
}
p {
  /* text-indent: 0.24rem; */
  margin-bottom: 0.08rem;
  font-size: 0.12rem;
  color: rgb(59, 59, 59);
  text-indent: unset;
}

img {
  width: 3.45rem;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 0.08rem;
}
</style>