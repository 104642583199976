<template>
  <!-- 随访用户协议 -->
  <div class="box6">
    <h4 style="text-align:center;margin-bottom:0.1rem">宏康随访用户协议</h4>
    <p>宏康随访通过健康设备和智能手机、云智能分析后台为您提供服务。如果您创建了宏康随访帐号，并通过以该帐号作为身份与我们分享您的信息，我们将为您提供更好的服务，如基于您和您家人的健康的整体状况，做出更有针对性的建议、指引及健康提醒等。本应用的所有者日记本（北京）健康科技有限公司承诺保护您的在线隐私权。请阅读下列声明以便了解您在本网站登录、注册、以及使用本网站服务时，您的个人信息将会得到的对待。本隐私权声明可能不时更改，请定期查阅。</p>
    <h4>一、 如何收集数据</h4>
    <p><span>1、您向我们提供的信息。当您注册宏康随访帐户后，您所提交的用户信息，例如用户昵称，生日，身高，体重等属于此类数据。我们收集此类数据主要目的是为了让系统能够找到更符合您和您家人具体情况的健康信息，并为您和您的家人做健康趋势分析，使您获得非凡的健康信息服务体验。我们会一直保证所需信息的最小化。 </span></p>
    <p><span>2、</span>我们在您使用服务过程中获取信息。我们会收集关于您使用的服务以及使用方式的信息，此类信息包括设备，时间，位置，IP地址，唯一匿名标识符等。收集此类信息的目的是为了让我们可以更好的了解整个用户群体的设备比例，地区分布，使用时段等，可以让我们有更好的参照和目标来优化系统，为用户提供更优质的服务。</p>
    <h4>二、 个人信息的使用</h4>
    <p>我们收集信息是为了向所有用户提供更优质的服务，收集的信息用于提供、维护、保护和改进我们的服务，并利用这些信息为您提供定制内容，例如推荐健康服务信息等。个人信息将主要用于以下几个目的：</p>
    <p><span>1、</span>完成您对特定服务或产品的要求。</p>
    <p><span>2、</span>通过各种方式（包括但不限于电子邮件和手机短信息）向您提供宏康随访的特定服务、产品或信息。</p>
    <p><span>3、</span>用于对本应用用户数据的统计（如用户数量、构成、兴趣、表现等），以便我们向用户提供更好的服务和产品，或向第三方为了一定的合法目的披露该类统计数据。该类统计数据都是用户群整体的信息，而非个人的信息。</p>
    <h4>三、 个人信息的共享和披露</h4>
    <p>宏康随访将享有您的个人信息。一般情况下，在未得到您的同意之前，您的任何个人信息将不会被提供给无关的第三方。在下列情况下，我们会将您的个人信息提供给有关的第三方：</p>
    <p><span>1、</span>本应用的某些服务需由一个合作伙伴（如提供相关技术服务的公司、运输公司、邮寄公司、提供奖品的公司等）提供或共同提供。为了向您提供此类服务，本应用有必要与该合作伙伴分享您的个人信息。如果您不希望您的信息被分享，您可以不使用该具体服务从而拒绝您的个人信息被披露。</p>
    <p><span>2、</span>本网站可能会与第三方共同进行推广活动。在推广活动中所收集的全部或部分个人信息可能会与该第三方分享。如果您不想让您的信息被分享，您可以通过不参加该推广活动而拒绝。</p>
    <p><span>3、</span>根据法律法规或政府的强制性规定，我们必须向有关司法或政府部门提供您的个人信息。</p>
    <p><span>4、</span>为了防止他人的合法权益或社会公共利益受到重大危害。</p>
    <p><span>5、</span>为了防止您的合法权益受到重大危害。</p>
    <p><span>6、</span>我们发现您的行为违反了本网站的服务条款或关于特定服务或产品的任何使用指引，或对宏康随访的合法权益构成重大危害。</p>
    <h4>四、 用户权利</h4>
    <p>您可以在任何时候通过使用您的本应用的注册用户名和密码，查询，补充或更正您的本应用帐户中的个人信息，或要求删除您的本应用帐户中的个人信息。您在本应用的帐户可以被删除，但这样会导致您不能够登录本网站及使用本网站的任何服务。</p>
    <h4>五、 信息安全</h4>
    <p>您的帐户信息和档案通过密码来保护，只有您个人才有权获取这些个人信息。我们建议您不要向任何他人泄露您的密码。我们不会主动致电或主动发电子邮件给您来询问您的密码。任何通过互联网或无线网络的数据传输都无法获得绝对的安全保证。所以，在我们尽最大努力来保护您的个人信息的同时，我们不能对您向我们进行的信息传输或我们所发的在线产品或服务的信息的安全作出肯定或保证。您作出上述行为时将自己承担凤险。一旦我们收到您传输来的信息，我们将尽最大努力保证其在我们系统的安全。</p>
    <h4>六、 免责</h4>
    <p>在下列情况下，我们无需承担任何责任：</p>
    <p><span>1、</span>任何根据法律法规或政府的强制性规定所作的对于个人信息的披露。</p>
    <p><span>2、</span>由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄露。</p>
    <p><span>3、</span>任何由于黑客攻击、计算机病毒侵入或发作、因政府官制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人信息泄露、丢失、被盗用或被窜改等。</p>
    <p><span>4、</span>由于我们不拥有或不控制的公司以及我们不雇佣或不管理的人员所造成的个人信息的泄露及由此而导致的任何法律争议和后果。</p>
    <h4>七、 本隐私权声明的修改及解释</h4>
    <p>本隐私权声明可能会不时被修改。本隐私权声明的修改权和最终解释权归日记本（北京）健康科技有限公司所有。</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.box6 {
  width: 3.45rem;
  font-size: 0.1rem;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 0.14rem;
}
h4{
  margin-top: 0.1rem;
  margin-bottom: 0.04rem;
}
</style>