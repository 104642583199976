<template>
  <div id="app">
    <!-- 消毒机IOS用户协议 -->
    <Useragreement v-if="type == 1" />
    <!-- 消毒机app用户协议 -->
    <Useragreement2 v-if="type == 10" />
    <!-- 消毒机IOS隐私政策 -->
    <Privacypolicy v-if="type == 2" />
    <!-- 消毒机IOS用户手册 -->
    <Usermanual v-if="type == 3" />
    <!-- 安卓的分享出去的新闻详情页面 -->
    <Anzhuosharnews v-if="type == 4" />
    <!-- 随访的用户协议 -->
    <Suifangpriv v-if="type == 5" />
    <Suifangagreement v-if="type == 6" />

    <!--  安卓IOS隐私政策 -->
    <AnzhuoPrivacypolicy v-if="type == 7" />
    <!-- 天青 -->
    <AnzhuoPrivacypolicy2 v-if="type == 17" />

    <!-- 消毒机检测报告 -->
    <Testreport v-if="type == 8" />

    <!-- 关于我们 -->
    <Aboutus v-if="type == 9" />

    <!-- 随访的隐私政策 -->
    <!-- IOS折线图页面-->
    <IOSzhexian v-if="isshowzhexian" />

    <!-- 404 -->
  </div>
</template>

<script>
import Useragreement from "./components/User_agreement.vue";
import Useragreement2 from "./components/User_agreement2";
import Privacypolicy from "./components/Privacy_policy.vue";
import Usermanual from "./components/User_manual.vue";
import Anzhuosharnews from "./components/anzhuo_shar_news.vue";
import IOSzhexian from "./components/IOS_zhexian.vue";
import Suifangpriv from "./components/suifang_Privacypolicy.vue";
import Suifangagreement from "./components/suifang_agreement.vue";
import AnzhuoPrivacypolicy from "./components/Anzhuo_Privacypolicy.vue";
import AnzhuoPrivacypolicy2 from "./components/Anzhuo_Privacypolicy2.vue";
import Testreport from "./components/testreport.vue";
import Aboutus from "./components/Aboutus.vue";

import { Base64 } from "js-base64";

export default {
  name: "App",
  components: {
    Useragreement,
    Privacypolicy,
    Usermanual,
    Anzhuosharnews,
    IOSzhexian,
    Suifangpriv,
    Suifangagreement,
    AnzhuoPrivacypolicy,
    Testreport,
    Aboutus,
    Useragreement2,
    AnzhuoPrivacypolicy2
  },
  data () {
    return {
      type: 0,
      isshowzhexian: false,
    };
  },
  created () {
    this.type = this.getQueryVariable("type");
    this.iszhexian();
    // console.log(this.type, "截取的type");
  },
  mounted () {
    let from = this.getQueryVariable("from");
    let str = this.getQueryVariable("token");
    // console.log(from,'from')
    if (str) {
      if (from) {
        this.token = Base64.decode(
          Base64.decode(str).substring(2, Base64.decode(str).length - 2)
        ).replace(/\+/g, "-");
      } else {
        this.token = Base64.decode(
          Base64.decode(str).substring(1, Base64.decode(str).length - 1)
        ).replace(/\+/g, "-");
      }
      sessionStorage.setItem("anzhuorestreporttoken", this.token);
    }
  },

  methods: {
    // 判断是否显示折线
    iszhexian () {
      //       (type == 'formaldehyde' ? true : false) ||
      // (type == 'tvoc' ? true : false) ||
      // (type == 'temperature' ? true : false) ||
      // (type == 'pm25' ? true : false) ||
      // (type == 'humidity' ? true : false)
      var type = this.getQueryVariable("type");
      if (
        type == "formaldehyde" ||
        type == "temperature" ||
        type == "tvoc" ||
        type == "pm25" ||
        type == "humidity"
      ) {
        this.isshowzhexian = true;
      } else {
        this.isshowzhexian = false;
      }
    },
    // 处理url
    getQueryVariable (variable) {
      // 截取 '?' 后的url 带 '?'
      var query = window.location.search.substring(1);
      // console.log(query,'query')
      var vars = query.split("&");
      // console.log(vars,'vars')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  font-size: 26.67vw;
  height: 100%;
}
#app {
  height: 100%;
}
</style>